import React, { FC, useRef } from 'react';
import { MenuItemProps, MenuItemVariants } from './MenuItem.types';
import { animated, useSpring, easings } from '@react-spring/web';
import { nanoid } from 'nanoid';

// components
import { Box, Stack } from 'src/components-v2/Layout';
import { Typography, Icon } from 'src/components-v2/DataDisplay';
import { useFocusManager } from '@react-aria/focus';

const AnimatedBox = animated(Box);

const MenuItem: FC<MenuItemProps> = ({
  title,
  children,
  variant,
  index,
  isActive,
  isMobile,
  activeIndex,
  onClick,
  onHide,
  onShow,
  sx,
}) => {
  const focusManager = useFocusManager();
  const liRef = useRef<HTMLDivElement>();
  const buttonRef = useRef<HTMLDivElement>();
  const menuRef = useRef<HTMLDivElement>();
  const menuItemId = `menuItemId-${nanoid()}`;
  const isPrimary = variant === MenuItemVariants.PRIMARY;
  const isSecondary = variant === MenuItemVariants.SECONDARY;

  const desktopItemSlideAnimation = useSpring({
    from: { opacity: '0', transform: 'translateY(40px)' },
    to: {
      opacity: isActive ? '100%' : '0',
      transform: isActive ? 'translateY(0px)' : 'translateY(40px)',
    },
    config: {
      duration: 300,
      easing: easings.easeOutQuint,
    },
  });

  const desktopItemFadeAnimation = useSpring({
    from: { opacity: '0' },
    to: {
      opacity: isActive ? '100%' : '0',
    },
    config: {
      duration: 150,
      easing: easings.easeOutQuint,
    },
  });

  const desktopMenuItemAnimation =
    activeIndex !== index && activeIndex !== -1
      ? desktopItemFadeAnimation
      : desktopItemSlideAnimation;

  const caretAnimation = useSpring({
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: isActive ? 'rotate(-180deg)' : 'rotate(0deg)',
    },
    config: {
      duration: 150,
      easing: easings.easeInQuad,
    },
  });

  const clickOrHoverProps: React.DOMAttributes<any> = isMobile
    ? {
        onClick(e) {
          // stop event from propagating to keep parent menu open on mobile
          e.stopPropagation();
          onClick();
        },
      }
    : {
        onFocus() {
          if (isSecondary) {
            onShow();
          }
        },
        onMouseEnter() {
          onShow();
        },
        onMouseLeave() {
          onHide();
        },
        onKeyDown(e) {
          switch (e.key) {
            case 'ArrowDown':
              e.preventDefault();
              e.stopPropagation();

              if (isPrimary) {
                onShow();
                // wait for next animation frame
                // so secondary item is focusable
                requestAnimationFrame(() => {
                  // focus first focusable item in secondary menu
                  const el =
                    menuRef.current.querySelector<HTMLElement>(
                      '[tabindex="0"]',
                    );
                  el.focus();
                });
              } else {
                focusManager.focusNext({ wrap: true });
              }
              break;
            case 'ArrowUp':
              if (!isPrimary) {
                e.preventDefault();
                e.stopPropagation();
                focusManager.focusPrevious({ wrap: true });
              }
              break;
            case 'Tab':
              // when active, hide the menu and return
              // this allows for the next element to focus
              if (isPrimary && isActive) {
                onHide();
                return;
              }
              break;
          }
        },
        onKeyUp(e) {
          switch (e.key) {
            case 'Enter':
            case ' ':
              if (isPrimary) {
                if (isActive) {
                  onHide();
                } else {
                  onShow();
                }
                return;
              }
              if (isSecondary) {
                e.preventDefault();
                e.stopPropagation();
              }
              break;
            case 'Escape':
              // only hide primary menu when Escape is pressed
              if (isPrimary) {
                onHide();
                // on next animation frame, put focus back on button
                requestAnimationFrame(() => {
                  buttonRef.current.focus();
                });
              }
              break;
          }
        },
      };

  return (
    <Stack
      component='li'
      sx={{
        position: { lg: 'relative' },
        width: { lg: isSecondary ? '100%' : 'auto' },
        ...sx,
      }}
      ref={liRef}
      {...clickOrHoverProps}
    >
      <Stack
        ref={buttonRef}
        component='button'
        role='button'
        aria-expanded={isActive}
        aria-controls={menuItemId}
        data-variant={variant}
        id={`${menuItemId}-control`}
        flexDirection='row'
        sx={{
          px: { xs: '0.8rem', sm: '4rem', lg: isPrimary ? '2rem' : '3.2rem' },
          py: {
            xs: isPrimary ? '1.575rem' : '1.925rem',
            sm: isPrimary ? '1.975rem' : '1.925rem',
            lg: isPrimary ? '0.8rem' : '1.2rem',
          },
          alignItems: 'center',
          justifyContent: { xs: 'space-between' },
          textAlign: 'inherit',
          borderTop: { xs: isPrimary ? 1 : 'none', lg: 'none' },
          borderTopColor: { xs: isPrimary ? 'black400' : 'none' },
          cursor: { lg: 'inherit' },
          backgroundColor: {
            lg: isSecondary && isActive ? 'black300' : 'none',
          },
          '&:hover': {
            backgroundColor: { lg: isSecondary ? 'black300' : 'none' },
          },
        }}
        tabIndex={0}
      >
        <Typography variant='p' sx={{ mb: '0' }} theme='main-menu'>
          {title}
        </Typography>
        <AnimatedBox
          style={caretAnimation}
          component='span'
          sx={{
            pt: '0.5rem',
            display: { lg: isSecondary ? 'none' : 'block' },
          }}
        >
          <Icon icon='ChevronDownS1' sx={{ color: 'black !important' }} />
        </AnimatedBox>
      </Stack>
      <AnimatedBox
        style={!isMobile ? desktopMenuItemAnimation : null}
        id={menuItemId}
        ref={menuRef}
        role='region'
        aria-labelledby={`${menuItemId}-control`}
        sx={{
          overflowY: 'hidden',
          position: { lg: isPrimary ? 'absolute' : 'none' },
          top: { lg: isPrimary ? '48px' : '0' },
          display: {
            xs: 'grid',
            lg: isSecondary ? 'none' : 'block',
          },
          gridTemplateRows: {
            xs: isActive ? '1fr' : '0fr',
            lg: 'unset',
          },
          transition: {
            xs: 'grid-template-rows 0.4s cubic-bezier(0.2, 1.2, 0.32, 1)',
            lg: 'unset',
          },
          willChange: 'grid-template-rows',
          pointerEvents: { lg: isActive ? 'auto' : 'none' },
          border: { lg: 1 },
          borderRadius: { lg: '8px' },
          borderColor: { lg: 'black400' },
          boxShadow: {
            lg: '0px 8px 16px 4px rgba(17, 17, 18, 0.08), 0px 0px 2px 0px rgba(17, 17, 18, 0.02);',
          },
          backgroundColor: { lg: 'white' },
        }}
      >
        <Box sx={{ overflow: 'hidden' }}>{children}</Box>
      </AnimatedBox>
    </Stack>
  );
};

export default MenuItem;
