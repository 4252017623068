import React from 'react';

const TwitterWhiteIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='50'
      height='50'
      viewBox='0 0 733 733'
      fill='none'
    >
      <path
        d='M91.625 641.375L322.166 410.834M322.166 410.834L91.625 91.625H244.333L410.834 322.166M322.166 410.834L488.667 641.375H641.375L410.834 322.166M641.375 91.625L410.834 322.166'
        stroke='#fff'
        strokeWidth='50.0544'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default TwitterWhiteIcon;
