import React from 'react';
import { animated, useSpring, easings } from '@react-spring/web';
import { Box, Stack } from 'src/components-v2/Layout';

const Bar = () => {
  return (
    <Box
      component='span'
      sx={{
        position: 'absolute',
        width: '18px',
        height: '2px',
        backgroundColor: 'black',
      }}
    />
  );
};
const HamburgerMenu = ({ isOpen }) => {
  const topLine = useSpring({
    from: {
      transform: 'translate(4px, 12px) rotate(0deg)',
    },
    to: {
      transform: isOpen
        ? 'translate(4px, 18px) rotate(45deg)'
        : 'translate(4px, 12px) rotate(0deg)',
    },
    config: {
      duration: 100,
      easing: easings.linear,
    },
  });
  const middleLine = useSpring({
    from: {
      opacity: 1,
      transform: 'translate(4px, 19px) rotate(0deg)',
    },
    to: {
      opacity: isOpen ? 0 : 1,
    },
    config: {
      duration: 100,
      easing: easings.linear,
    },
  });
  const bottomLine = useSpring({
    from: {
      transform: 'translate(4px, 26px) rotate(0deg)',
    },
    to: {
      transform: isOpen
        ? 'translate(4px, 18px) rotate(-45deg)'
        : 'translate(4px, 26px) rotate(0deg)',
    },
    config: {
      duration: 100,
      easing: easings.linear,
    },
  });

  return (
    <Stack
      sx={{
        position: 'relative',
        top: { xs: '-110%', sm: '-95%' },
        left: { xs: '-85%', sm: '-65%' },
      }}
    >
      <animated.span style={topLine}>
        <Bar />
      </animated.span>
      <animated.span style={middleLine}>
        <Bar />
      </animated.span>
      <animated.span style={bottomLine}>
        <Bar />
      </animated.span>
    </Stack>
  );
};

export default HamburgerMenu;
